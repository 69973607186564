//
// Core: Main Sidebar
//

.sidebar-bg-dark {
  @include nav-treeview-dark (
    $lte-sidebar-dark-bg,
    $lte-sidebar-dark-hover-bg,
    $lte-sidebar-dark-color,
    $lte-sidebar-dark-hover-color,
    $lte-sidebar-dark-active-color,
    $lte-sidebar-dark-submenu-bg,
    $lte-sidebar-dark-submenu-color,
    $lte-sidebar-dark-submenu-hover-color,
    $lte-sidebar-dark-submenu-hover-bg,
    $lte-sidebar-dark-submenu-active-color,
    $lte-sidebar-dark-submenu-active-bg,
    $lte-sidebar-dark-header-color
  );
}

.sidebar-bg-light {
  @include nav-treeview-light (
    $lte-sidebar-light-bg,
    $lte-sidebar-light-hover-bg,
    $lte-sidebar-light-color,
    $lte-sidebar-light-hover-color,
    $lte-sidebar-light-active-color,
    $lte-sidebar-light-submenu-bg,
    $lte-sidebar-light-submenu-color,
    $lte-sidebar-light-submenu-hover-color,
    $lte-sidebar-light-submenu-hover-bg,
    $lte-sidebar-light-submenu-active-color,
    $lte-sidebar-light-submenu-active-bg,
    $lte-sidebar-light-header-color
  );
}

// .sidebar-color-primary {
//   @include sidebar-color($primary);
// }

.main-sidebar {
  @include transition($lte-sidebar-transition);
  z-index: $lte-zindex-sidebar;
  grid-area: main-sidebar;
  min-width: $lte-sidebar-width;
  max-width: $lte-sidebar-width;

  .nav-sidebar > .nav-item {
    > .nav-link.active {
      color: var(--lte-sidebar-menu-active-color);
      background-color: var(--lte-sidebar-menu-active-bg);
    }
  }
}

.hold-transition {
  .main-sidebar {
    @include transition(none !important);
    animation: none !important;
  }
}
