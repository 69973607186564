// Sidebar navigation menu
.nav-sidebar {
  // All levels
  .nav-item {
    > .nav-link {
      margin-bottom: .2rem;

      .end {
        @include transition(transform $lte-transition-fn $lte-transition-speed);
        transform: none #{"/*rtl:rotate(-180deg)*/"};
      }
    }
  }

  // All levels
  .nav-link > .end,
  .nav-link > p > .end {
    position: absolute;
    top: .7rem;
    right: 1rem;
  }

  .nav-link {
    position: relative;
    width: subtract($lte-sidebar-width, $lte-sidebar-padding-x * 2);
    @include transition(width $lte-transition-fn $lte-transition-speed);

    p {
      display: inline;
      padding-left: .5rem;
      margin: 0;
    }

    .nav-icon {
      padding-left: .3rem;
    }
  }

  .nav-header {
    padding: $nav-link-padding-y ($nav-link-padding-y * 1.5);
    font-size: .9rem;
  }

  // Tree view menu
  .nav-treeview {
    display: none;
    padding: 0;
    list-style: none;
  }

  .menu-open {
    > .nav-treeview {
      display: block;
    }
  }

  .menu-open,
  .menu-is-opening {
    > .nav-link {
      svg.end,
      i.end {
        transform: rotate(90deg) #{"/*rtl:rotate(90deg)*/"};
      }
    }
  }
}
