@include media-breakpoint-up(lg) {
  .layout-fixed:not(.sidebar-horizontal) {
    .main-sidebar {
      position: fixed;
      top: auto;
      bottom: 0;
      left: 0;
      float: none;

      .sidebar {
        height: subtract(100vh, add($lte-main-header-height-inner, $lte-main-header-bottom-border-width));
      }
    }

    .main-header,
    .content-wrapper,
    .main-footer {
      margin-left: $lte-sidebar-width;
      @include transition($lte-transition-speed $lte-transition-fn);
    }

    &.sidebar-collapse {
      .main-header,
      .content-wrapper,
      .main-footer {
        margin-left: $lte-sidebar-mini-width;
      }
    }

    &.sidebar-close {
      .main-header,
      .content-wrapper,
      .main-footer {
        margin-left: 0;
      }
    }
  }

  // .layout-fixed.fs-7 .wrapper .sidebar {
  //   // stylelint-disable-next-line
  //   height: calc(100vh - (#{$lte-main-header-height-sm-inner} + #{$lte-main-header-bottom-border-width}));
  // }
}
